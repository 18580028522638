import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import 'react-image-lightbox/style.css'
import Layout from '../components/Layout'
import * as s from './lokasena.module.scss'
import Container from 'components/container'

export default () => {
  return (
    <Layout>
      <Helmet title="Lokasena" />
      <div className={s.lokasena}>
        <Container>
          <Link to="/" className={s.link}>
            <svg
              width="36"
              height="11"
              viewBox="0 0 36 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36 4.41755H5.76135V0L0 5.50011L5.76135 11V6.58252H36V4.41755Z"
                fill="#26e6ac"
              />
            </svg>
            <span>Forsíða</span>
            <br />
            <br />
          </Link>
          <h2 className={s.title}>Logline</h2>
          <div className={s.content}>
            <p>
              Gréta, 21, 800 metra hlaupari, flytur á höfuðborgarsvæðið og er staðráðin í að ráða
              Magnús, 22, til starfa sem einkaþjálfara, með það markmið að verða besti hlaupari
              landsins. Fjölskyldumissir hjá þeim báðum hefur áhrif á ferðalagið í átt að
              markmiðinu.
            </p>
            <br />
          </div>
        </Container>
      </div>
    </Layout>
  )
}
